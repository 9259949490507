import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/home/Home";
import NewsPage from "./pages/news/NewsPage";
import Contact from "./pages/contact/Contact";
import ESG from "./pages/esg/ESG";
import ProjectDetail from "./pages/project-detail/ProjectDetial";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/project-detail/:id",
    element: <ProjectDetail />,
  },
  {
    path: "/news",
    element: <NewsPage />,
  },
  {
    path: "/about",
    element: <Contact />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/esg",
    element: <ESG />,
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
