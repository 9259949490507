import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { styled } from "styled-components";
import config from "./config";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { BackwardOutlined, ForwardOutlined } from "@ant-design/icons";

const Container = styled.div`
  width: 1123px;
  margin: 0 auto;
`;

const ImageContainer = styled.div``;

const ImageItem = styled.img`
  display: block;
  width: 100%;
`;

const ControlContainer = styled.div`
  height: 30px;
  width: 100%;
`;

const ControlContent = styled.div`
  height: 100%;
  width: 100%;
  background: black;
  color: white;
  font-size: 26px;
  position: relative;
`;

const Backward = styled.div`
  position: absolute;
  left: 5px;
  &:hover {
    cursor: pointer;
  }
`;

const Forward = styled.div`
  position: absolute;
  right: 5px;
  &:hover {
    cursor: pointer;
  }
`;

const ProjectListContainer = styled.div`
  padding-bottom: 80px;
`;

const ProjectListTitle = styled.div`
  position: relative;
  height: 28px;
  overflow: hidden;
`;

const ProjectListTitleMore = styled.p`
  position: absolute;
  right: 15px;
  bottom: 0;
  margin: 0;
  background: white;
  padding: 0 16px;
  font-size: 14px;
`;

const ProjectListTitleH2 = styled.p`
  margin: 10px 0 0 15px;
`;

const ProjectListTitleH2CN = styled.span``;

const ProjectListTitleH2EN = styled.span`
  margin-right: 4px;
  background: white;
`;

const ProjectList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ProjectListItem = styled(Link)`
  width: 366px;
  box-sizing: border-box;
  margin: 4px;
`;

const ProjectDetail = () => {
  const { id } = useParams();
  const [images, setImages] = useState<string[]>([]);
  const [recommendList, setRecommendList] = useState<typeof config>([])

  useEffect(() => {
    const current = config.find((item) => item.id === id);
    if (!current) {
      // TODO redirect to home page
      return;
    }

    setImages(current.images);
  }, [id]);

  useEffect(() => {
    let currentArr = config.filter((item) => item.id !== id);
    const total = 3;
    const newArr:typeof config = []
    for (let index = 0; index < total; index++) {
      const ranIndex = Math.floor(Math.random() * currentArr.length);
      newArr.push(currentArr[ranIndex])
      currentArr.filter((_, index) => index !== ranIndex);
    }
    setRecommendList(newArr)
  }, [id])

  return (
    <Container>
      <NavBar />
      <ControlContainer>
        <ControlContent>
          <Backward>
            <BackwardOutlined />
          </Backward>
          <Forward>
            <ForwardOutlined />
          </Forward>
        </ControlContent>
      </ControlContainer>
      <ImageContainer>
        {images.map((image) => (
          <ImageItem key={Math.random()} src={image} alt="" />
        ))}
      </ImageContainer>
      <ProjectListContainer>
        <ProjectListTitle>
          <ProjectListTitleH2>
            <ProjectListTitleH2EN>BRAND</ProjectListTitleH2EN>
            <ProjectListTitleH2CN>相关内容</ProjectListTitleH2CN>
          </ProjectListTitleH2>
          <ProjectListTitleMore>MORE</ProjectListTitleMore>
        </ProjectListTitle>
        <ProjectList>
          {
            recommendList.map(item => (
<ProjectListItem to={`/project-detail/NEW-PHONE-Conceptual-Design`}>
            {/* <ProjectListItemImage src={item.} /> */}
          </ProjectListItem>
            ))
          }
          
        </ProjectList>
      </ProjectListContainer>
      <Footer />
    </Container>
  );
};

export default ProjectDetail;
