import { styled } from "styled-components";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { useEffect, useState } from "react";
import { News } from "../../types/news";
import newsService from "../../services/newsService";
import "./NewsPage.css";

const Container = styled.div`
  width: 1123px;
  margin: 0 auto;
`;

const NewsPage = () => {
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [total, setTotal] = useState<number>(0);
  const [fetchData, setFetchData] = useState(true);
  const [newsList, setNewsList] = useState<News[]>([]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await newsService.getNews({ pageIndex, pageSize });
        setNewsList(res.data.news);
        setTotal(res.data.total);
      } catch (e) {
        //
      } finally {
        setFetchData(false);
      }
    };

    if (fetchData) {
      getNews();
    }
  }, [fetchData]);

  return (
    <Container>
      <NavBar />
      {newsList.map((item) => (
        <div className="ck-content" dangerouslySetInnerHTML={{ __html: item.Content }}></div>
      ))}
      <Footer />
    </Container>
  );
};

export default NewsPage;
