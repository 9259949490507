import { styled } from "styled-components";
import NavBar from "../../components/NavBar";
import bannerImg from "./images/banner_img.jpg";
import event_0 from "../../images/event_0.jpg";
import event_1 from "../../images/event_1.jpg";
import event_2 from "../../images/event_2.jpg";
import event_3 from "../../images/event_3.jpg";
import event_4 from "../../images/event_4.jpg";
import event_5 from "../../images/event_5.jpg";
import project_0 from "./images/project_0.jpg";
import project_1 from "./images/project_1.jpg";
import project_2 from "./images/project_2.jpg";
import project_3 from "./images/project_3.jpg";
import project_4 from "./images/project_4.jpg";
import project_5 from "./images/project_5.jpg";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

const Container = styled.div`
  width: 1123px;
  margin: 0 auto;
`;

const BannerContainer = styled.div``;
const Banner = styled.img`
  display: block;
`;

const EventContainer = styled.div`
  background: black;
`;

const EventImages = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
`;

const EventImageLink = styled(Link)`
  display: block;
  width: 100px;
  flex-shrink: 1;
  flex-grow: 1;
  @media screen and (max-width: 1024px) {
    width: 375px;
    flex-shrink: 0;
  }
`;

const EventImage = styled.img`
  display: block;
  width: 100%;
`;

const EventTitleH2 = styled.h2`
  font-weight: normal;
  font-size: 16px;
  margin: 0 0 3px 0;
  @media screen and (max-width: 1024px) {
    font-size: 48px;
  }
`;

const EventTitleDesc = styled.p`
  margin: 0;
  font-size: 12px;
  @media screen and (max-width: 1024px) {
    font-size: 36px;
  }
`;

const EventTitles = styled.div`
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;
  padding: 18px 0 12px 0;
`;

const EventTitleMore = styled.p`
  position: absolute;
  right: 26px;
  bottom: -20px;
  font-size: 12px;
  @media screen and (max-width: 1024px) {
    font-size: 36px;
  }
`;

const ProjectListContainer = styled.div`
  padding-bottom: 80px;
`;

const ProjectListTitle = styled.div`
  position: relative;
  min-height: 28px;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    font-size: 42px;
  }
`;

const ProjectListTitleMore = styled.p`
  position: absolute;
  right: 15px;
  bottom: 0;
  margin: 0;
  background: white;
  padding: 0 16px;
  font-size: 14px;
  @media screen and (max-width: 1024px) {
    font-size: 42px;
  }
`;

const ProjectListTitleH2 = styled.p`
  margin: 10px 0 0 15px;
`;

const ProjectListTitleH2CN = styled.span``;

const ProjectListTitleH2EN = styled.span`
  margin-right: 4px;
  background: white;
`;

const ProjectList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ProjectListItem = styled(Link)`
  width: 366px;
  box-sizing: border-box;
  margin: 4px;
  @media screen and (max-width: 1024px) {
    width: 553px;
  }
`;

const ProjectListItemImage = styled.img`
  width: 100%;
  display: block;
`;

const Home = () => {
  return (
    <Container>
      <NavBar />
      <BannerContainer>
        <Banner src={bannerImg} />
      </BannerContainer>
      <EventContainer>
        <EventTitles>
          <EventTitleH2>EVENTS</EventTitleH2>
          <EventTitleDesc>日本语~</EventTitleDesc>
          <EventTitleMore>MORE</EventTitleMore>
        </EventTitles>
        <EventImages>
          <EventImageLink to={`/project-detail/-MAX-`}>
            <EventImage src={event_0} />
          </EventImageLink>
          <EventImageLink to={`/project-detail/140643257`}>
            <EventImage src={event_1} />
          </EventImageLink>
          <EventImageLink to={`/project-detail/137875093`}>
            <EventImage src={event_2} />
          </EventImageLink>
          <EventImageLink to={`/project-detail/138369065`}>
            <EventImage src={event_3} />
          </EventImageLink>
          <EventImageLink to={`/project-detail/141068917`}>
            <EventImage src={event_4} />
          </EventImageLink>
          <EventImageLink to={`/project-detail/144000357`}>
            <EventImage src={event_5} />
          </EventImageLink>
        </EventImages>
      </EventContainer>
      <ProjectListContainer>
        <ProjectListTitle>
          <ProjectListTitleH2>
            <ProjectListTitleH2EN>PROJECT</ProjectListTitleH2EN>
            <ProjectListTitleH2CN>项目案例</ProjectListTitleH2CN>
          </ProjectListTitleH2>
          <ProjectListTitleMore>MORE</ProjectListTitleMore>
        </ProjectListTitle>
        <ProjectList>
          <ProjectListItem to={`/project-detail/NEW-PHONE-Conceptual-Design`}>
            <ProjectListItemImage src={project_0} />
          </ProjectListItem>
          <ProjectListItem
            to={"/project-detail/ATF-1ST-Album-Visual-Upgrade-Program"}
          >
            <ProjectListItemImage src={project_1} />
          </ProjectListItem>
          <ProjectListItem
            to={"/project-detail/Valsfer-Visual-Upgrade-Program"}
          >
            <ProjectListItemImage src={project_2} />
          </ProjectListItem>
          <ProjectListItem to={"/project-detail/XDE-Visual-Upgrade-Program"}>
            <ProjectListItemImage src={project_3} />
          </ProjectListItem>
          <ProjectListItem
            to={"/project-detail/ATF-1ST-Anniversary-Visual-Upgrade-Program"}
          >
            <ProjectListItemImage src={project_4} />
          </ProjectListItem>
          <ProjectListItem
            to={"/project-detail/SHIMADZO-Product-Photography-2014-2016"}
          >
            <ProjectListItemImage src={project_5} />
          </ProjectListItem>
        </ProjectList>
      </ProjectListContainer>
      <Footer />
    </Container>
  );
};

export default Home;
