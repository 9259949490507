import queryString from "query-string";
import adminRequest from "../utils/request";
import { News } from "../types/news";

class NewsService {
    async getNews(data: { pageIndex: number; pageSize: number }) {
        const url = queryString.stringifyUrl({
          url: `/api/news`,
          query: data,
        });
        return adminRequest.get<{
          news: News[];
          pageIndex: number;
          pageSize: number;
          total: number;
        }>(url);
      }
}

export default new NewsService();
