import styled from "styled-components";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import theIcon from "../../images/the_icon.svg";

const Container = styled.div`
  width: 1123px;
  margin: 0 auto;
`;

const Content = styled.div`
  background: black;
  margin-bottom: 80px;
  padding-bottom: 80px;
  color: white;
`;

const IntroContainer = styled.div`
  display: flex;
  padding-top: 80px;
`;

const IntroAbout = styled.div`
  width: 200px;
  padding-left: 20px;
  @media screen and (max-width: 1024px) {
    width: 400px;
    padding-left: 50px;
  }
`;

const IntroAboutH2 = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  p {
    margin: 0;
  }
  @media screen and (max-width: 1024px) {
    font-size:60px;
  }
`;

// const IntroAboutH3 = styled.div`
//   font-size: 12px;
//   p {
//     margin: 0;
//   }
// `;

const IntroDesc = styled.div`
  @media screen and (max-width: 1024px) {
    padding-top: 8px;
    font-size:30px;
  }`
  ;

const IntroDescText = styled.div`
  margin-bottom: 20px;
`;

const ContactContainer = styled.div`
  display: flex;
  margin-top: 40px;
`;

const ContactSub = styled.div`
  width: 200px;
  padding-left: 20px;
  @media screen and (max-width: 1024px) {
    width: 400px;
    padding-left: 50px;
  }
`;

const ContactSubH1 = styled.div`
  font-size: 18px;
  span {
    background: #e83e11;
    padding: 0 8px;
    display: inline-block;
    line-height: 14px;
    height: 16px;
    font-weight: 200;
  }
  @media screen and (max-width: 1024px) {
    font-size:60px;
    span {
      padding: 0 8px;
      line-height: 60px;
      height: 60px;
    }
  }
`;

const ContactMain = styled.div`
  @media screen and (max-width: 1024px) {
    width: 600px;
  }
`;

const ContactMainText = styled.p`
  margin: 0 0 6px 0;
  font-size: 16px;
  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }
`

const TheIconMainContainer = styled.div`
  position: relative;
  margin-top: 120px;
`;

const TheIconMainText = styled.div`
  position: absolute;
  font-size: 120px;
  font-weight: bold;
  top: 0;
  left: 20px;
`;

const TheIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TheIcon = styled.img`
  display: block;
  width: 700px;
`;

const Contact = () => {
  return (
    <Container>
      <NavBar />
      <Content>
        <IntroContainer>
          <IntroAbout>
            <IntroAboutH2>
              <p>ABOUT</p>
              <p>介绍</p>
            </IntroAboutH2>
            {/* <IntroAboutH3>
              <p>工作动态</p>
              <p>日常</p>
            </IntroAboutH3> */}
          </IntroAbout>
          <IntroDesc>
            <IntroDescText>Pentagram(五角设计联盟)</IntroDescText>
            <IntroDescText>人们通常会从设计</IntroDescText>
          </IntroDesc>
        </IntroContainer>
        <ContactContainer>
          <ContactSub>
            <ContactSubH1>
              <span>CONTACT</span>
            </ContactSubH1>
            <ContactSubH1>联络我们</ContactSubH1>
          </ContactSub>
          <ContactMain>
            <ContactMainText>公司名称：Pentagram</ContactMainText>
            <ContactMainText>中文名称：午觉设计联盟、五行设计、五角星设计</ContactMainText>
            <ContactMainText>总部：英国伦敦</ContactMainText>
            <ContactMainText>公司官网：https://www.pentagram.com</ContactMainText>
          </ContactMain>
        </ContactContainer>
        <TheIconMainContainer>
          <TheIconMainText>SPD</TheIconMainText>
          <TheIconContainer>
            <TheIcon src={theIcon} />
          </TheIconContainer>
        </TheIconMainContainer>
      </Content>
      <Footer />
    </Container>
  );
};

export default Contact;
