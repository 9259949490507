import { styled } from "styled-components";
import footerLogo from "../images/footer_logo.svg";
import theIcon from "../images/the_icon.svg";
import { Link, NavLink } from "react-router-dom";
import connectBE from "../images/connect_be_white.svg";

const Container = styled.div`
  min-height: 330px;
  background: black;
`;

const ConnectBEContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;
const ConnectBE = styled.img`
  width: 298px;
  margin: 22px 45px 6px 0;
`;

const NavMapAndCatlog = styled.div`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const NavMap = styled.div`
  display: flex;
  color: white;
  margin: 0 0 14px 30px;
`;

const NavMapItem = styled(NavLink)`
  width: 150px;
  color: white;
  text-decoration: none;
  &.active p:nth-of-type(1) span {
    background: #e83e11;
  }
  &:hover p:nth-of-type(1) span {
    background: #e83e11;
  }
`;

const NavMapText = styled.p`
  margin: 0;
`;

const NavCatlog = styled.div`
  display: flex;
  color: white;
  font-size: 12px;
  margin-left: 30px;
`;

const NavCatlogItem = styled.div`
  width: 150px;
`;

const NavCatlogText = styled.p`
  margin: 0;
  font-size: 12px;
`;

const NavContainer = styled.div`
  display: flex;
  padding-top: 60px;
  @media screen and (max-width: 1024px) {
    padding-top: 30px;
  }
`;

const SubInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const SPLogoContainer = styled.div`
  width: 100px;
  flex-shrink: 1;
  flex-grow: 1;
`;

const SubInfoCopyRight = styled.div`
  width: 100px;
  flex-shrink: 1;
  flex-grow: 1;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const SubInfoBlogButtonContainer = styled.div`
  width: 100px;
  flex-shrink: 1;
  flex-grow: 1;
`;

const ICPInfo = styled.a`
  margin: 10px 0 0 30px;
  color: white;
  font-size: 12px;
  display: block;
  text-decoration: none;
`

const SubInfoBlogButton = styled.div`
  width: 233px;
  height: 50px;
  border: 1px solid white;
  margin-left: 30px;
  color: white;
  font-weight: bold;
  line-height: 50px;
  font-size: 16px;
  box-sizing: border-box;
  padding-left: 15px;
  @media screen and (max-width: 1024px) {
    width: 300px;
    height: 90px;
    line-height: 86px;
    text-align: center;
    font-size: 48px;
  }
`;

const SPLogo = styled.img`
  display: block;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    width: 150px;
  }
`;

const TheIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const TheIcon = styled.img`
  display: block;
  width: 116px;
  margin-right: 30px;
`;

const TheIconMobileContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100px;
  flex-shrink: 1;
  flex-grow: 1;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const TheIconMobile = styled.img`
  display: block;
  width: 136px;
  margin-right: 30px;
`;

const SubInfoCopyRightMobile = styled.div`
  color: white;
  width: 1050px;
  margin: 40px auto 0;
  padding-bottom: 10px;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const Footer = () => {
  return (
    <Container>
      <NavContainer>
        <NavMapAndCatlog>
          <NavMap>
            <NavMapItem to="/">
              <NavMapText>
                <span>PROJECTS</span>
              </NavMapText>
              <NavMapText>项目</NavMapText>
            </NavMapItem>
            <NavMapItem to="/esg">
              <NavMapText>
                <span>ESG</span>
              </NavMapText>
              <NavMapText>环境</NavMapText>
            </NavMapItem>
            <NavMapItem to="/news">
              <NavMapText>
                <span>NEWS</span>
              </NavMapText>
              <NavMapText>动态</NavMapText>
            </NavMapItem>
            <NavMapItem to="/about">
              <NavMapText>
                <span>ABOUT</span>
              </NavMapText>
              <NavMapText>介绍</NavMapText>
            </NavMapItem>
            <NavMapItem to="/contact">
              <NavMapText>
                <span>CONTACT</span>
              </NavMapText>
              <NavMapText>联络我们</NavMapText>
            </NavMapItem>
          </NavMap>
          <NavCatlog>
            <NavCatlogItem>
              <NavCatlogText>品牌设计</NavCatlogText>
              <NavCatlogText>商品包装设计</NavCatlogText>
              <NavCatlogText>视觉表达</NavCatlogText>
            </NavCatlogItem>
            <NavCatlogItem>
              <NavCatlogText>生产链路减碳</NavCatlogText>
              <NavCatlogText>材料与结构</NavCatlogText>
            </NavCatlogItem>
            <NavCatlogItem>
              <NavCatlogText>工作动态</NavCatlogText>
              <NavCatlogText>日常</NavCatlogText>
            </NavCatlogItem>
            <NavCatlogItem>
              <NavCatlogText>上海市杨浦区</NavCatlogText>
              <NavCatlogText>邮件</NavCatlogText>
              <NavCatlogText>电话</NavCatlogText>
            </NavCatlogItem>
          </NavCatlog>
        </NavMapAndCatlog>
        <TheIconContainer>
          <TheIcon src={theIcon} />
        </TheIconContainer>
      </NavContainer>
      <div>
        <SubInfoContainer>
          <SubInfoBlogButtonContainer>
            <ConnectBEContainer>
              <Link to="https://www.behance.net/min_zhou87d7">
                <ConnectBE src={connectBE} />
              </Link>
            </ConnectBEContainer>
            <SubInfoBlogButton>BLOG</SubInfoBlogButton>
            <ICPInfo href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2023021394号-1</ICPInfo>
          </SubInfoBlogButtonContainer>
          <SPLogoContainer>
            <SPLogo src={footerLogo} />
          </SPLogoContainer>
          <TheIconMobileContainer>
            <TheIconMobile src={theIcon} />
          </TheIconMobileContainer>
          <SubInfoCopyRight>
            Copyright the ownership belongs to TNTsell, shall not be reproduced,
            copied, or used in other ways without permission. Otherwise TNTsell
            will have the right to pursue legal responsibilities.
          </SubInfoCopyRight>
        </SubInfoContainer>
        <SubInfoCopyRightMobile>
          Copyright ownership belongs to TNTsell, shall not be reproduced,
          copied, or used in other ways without permission. Otherwise TNTsell
          will have the right to pursue legal responsibilities.
        </SubInfoCopyRightMobile>
      </div>
    </Container>
  );
};

export default Footer;
