import { Link } from "react-router-dom";
import styled from "styled-components";
import event_0 from "../../images/event_0.jpg";
import event_1 from "../../images/event_1.jpg";
import event_2 from "../../images/event_2.jpg";
import event_3 from "../../images/event_3.jpg";
import event_4 from "../../images/event_4.jpg";
import event_5 from "../../images/event_5.jpg";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

const Container = styled.div`
  width: 1123px;
  margin: 0 auto;
`;

const ProjectList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  min-height: 1200px;
`;

const ProjectListItem = styled(Link)`
  display: block;
  width: 33.33%;
`;

const ProjectListItemImage = styled.img`
  display: block;
  width: 100%;
`;

const ESG = () => {
  return (
    <Container>
      <NavBar />
      <ProjectList>
        <ProjectListItem to={`/project-detail/-MAX-`}>
          <ProjectListItemImage src={event_0} />
        </ProjectListItem>
        <ProjectListItem to={`/project-detail/140643257`}>
          <ProjectListItemImage src={event_1} />
        </ProjectListItem>
        <ProjectListItem to={`/project-detail/137875093`}>
          <ProjectListItemImage src={event_2} />
        </ProjectListItem>
        <ProjectListItem to={`/project-detail/138369065`}>
          <ProjectListItemImage src={event_3} />
        </ProjectListItem>
        <ProjectListItem to="/project-detail/141068917">
          <ProjectListItemImage src={event_4} />
        </ProjectListItem>
        <ProjectListItem to={`/project-detail/144000357`}>
          <ProjectListItemImage src={event_5} />
        </ProjectListItem>
      </ProjectList>
      <Footer />
    </Container>
  );
};

export default ESG;
