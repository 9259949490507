interface Config {
  id: string;
  images: string[];
}

const config: Config[] = [
  {
    id: "NEW-PHONE-Conceptual-Design",
    images: [
      require("./images/NEW-PHONE-Conceptual-Design/0.jpg"),
      require("./images/NEW-PHONE-Conceptual-Design/1.jpg"),
      require("./images/NEW-PHONE-Conceptual-Design/2.jpg"),
      require("./images/NEW-PHONE-Conceptual-Design/3.jpg"),
      require("./images/NEW-PHONE-Conceptual-Design/4.jpg"),
      require("./images/NEW-PHONE-Conceptual-Design/5.jpg"),
      require("./images/NEW-PHONE-Conceptual-Design/6.jpg"),
    ],
  },
  {
    id: "ATF-1ST-Album-Visual-Upgrade-Program",
    images: [
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/0.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/1.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/2.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/3.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/4.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/5.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/6.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/7.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/8.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/9.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/10.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/11.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/12.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/13.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/14.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/15.jpg"),
      require("./images/ATF-1ST-Album-Visual-Upgrade-Program/16.jpg"),
    ],
  },
  {
    id: "Valsfer-Visual-Upgrade-Program",
    images: [
      require("./images/Valsfer-Visual-Upgrade-Program/0.jpg"),
      require("./images/Valsfer-Visual-Upgrade-Program/1.jpg"),
      require("./images/Valsfer-Visual-Upgrade-Program/2.jpg"),
      require("./images/Valsfer-Visual-Upgrade-Program/3.jpg"),
      require("./images/Valsfer-Visual-Upgrade-Program/4.jpg"),
      require("./images/Valsfer-Visual-Upgrade-Program/5.jpg"),
      require("./images/Valsfer-Visual-Upgrade-Program/6.jpg"),
      require("./images/Valsfer-Visual-Upgrade-Program/7.jpg"),
      require("./images/Valsfer-Visual-Upgrade-Program/8.jpg"),
      require("./images/Valsfer-Visual-Upgrade-Program/9.jpg"),
      require("./images/Valsfer-Visual-Upgrade-Program/10.jpg"),
      require("./images/Valsfer-Visual-Upgrade-Program/11.jpg"),
    ],
  },
  {
    id: "XDE-Visual-Upgrade-Program",
    images: [
      require("./images/XDE-Visual-Upgrade-Program/0.jpg"),
      require("./images/XDE-Visual-Upgrade-Program/1.jpg"),
      require("./images/XDE-Visual-Upgrade-Program/2.jpg"),
      require("./images/XDE-Visual-Upgrade-Program/3.jpg"),
      require("./images/XDE-Visual-Upgrade-Program/4.jpg"),
      require("./images/XDE-Visual-Upgrade-Program/5.jpg"),
      require("./images/XDE-Visual-Upgrade-Program/6.jpg"),
    ],
  },
  {
    id: "ATF-1ST-Anniversary-Visual-Upgrade-Program",
    images: [
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/0.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/1.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/2.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/3.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/4.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/5.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/6.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/7.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/8.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/9.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/10.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/11.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/12.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/13.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/14.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/15.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/16.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/17.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/18.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/19.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/20.jpg"),
      require("./images/ATF-1ST-Anniversary-Visual-Upgrade-Program/21.jpg"),
    ],
  },
  {
    id: "SHIMADZO-Product-Photography-2014-2016",
    images: [
      require("./images/SHIMADZO-Product-Photography-2014-2016/0.jpg"),
      require("./images/SHIMADZO-Product-Photography-2014-2016/1.jpg"),
      require("./images/SHIMADZO-Product-Photography-2014-2016/2.jpg"),
      require("./images/SHIMADZO-Product-Photography-2014-2016/3.jpg"),
      require("./images/SHIMADZO-Product-Photography-2014-2016/4.jpg"),
      require("./images/SHIMADZO-Product-Photography-2014-2016/5.jpg"),
      require("./images/SHIMADZO-Product-Photography-2014-2016/6.jpg"),
      require("./images/SHIMADZO-Product-Photography-2014-2016/7.jpg"),
      require("./images/SHIMADZO-Product-Photography-2014-2016/8.jpg"),
      require("./images/SHIMADZO-Product-Photography-2014-2016/9.jpg"),
    ],
  },
  {
    id: '-MAX-',
    images: [
        require("./images/-MAX-/0.jpg"),
        require("./images/-MAX-/1.gif"),
        require("./images/-MAX-/2.jpg"),
        require("./images/-MAX-/3.jpg"),
        require("./images/-MAX-/4.gif"),
        require("./images/-MAX-/5.jpg"),
        require("./images/-MAX-/6.gif"),
        require("./images/-MAX-/7.jpg"),
        require("./images/-MAX-/8.jpg"),
        require("./images/-MAX-/9.jpg"),
        require("./images/-MAX-/10.jpg"),
        require("./images/-MAX-/11.gif"),
        require("./images/-MAX-/12.jpg"),
        require("./images/-MAX-/13.jpg"),
        require("./images/-MAX-/14.jpg"),
        require("./images/-MAX-/15.jpg"),
        require("./images/-MAX-/16.jpg"),
        require("./images/-MAX-/17.jpg"),
        require("./images/-MAX-/18.jpg"),
        require("./images/-MAX-/19.jpg"),
        require("./images/-MAX-/20.png"),
        require("./images/-MAX-/21.jpg"),
        require("./images/-MAX-/22.jpg"),
        require("./images/-MAX-/23.jpg"),
        require("./images/-MAX-/24.jpg"),
        require("./images/-MAX-/25.jpg"),
        require("./images/-MAX-/26.jpg"),
        require("./images/-MAX-/27.jpg"),
        require("./images/-MAX-/28.jpg"),
        require("./images/-MAX-/29.jpg"),
        require("./images/-MAX-/30.jpg"),
        require("./images/-MAX-/31.jpg"),
        require("./images/-MAX-/32.jpg"),
        require("./images/-MAX-/33.jpg"),
        require("./images/-MAX-/34.jpg"),
        require("./images/-MAX-/35.jpg"),
        require("./images/-MAX-/36.jpg"),
    ]
  },
  {
    id: '140643257',
    images: [
        require("./images/140643257/0.jpg"),
        require("./images/140643257/1.jpg"),
        require("./images/140643257/2.jpg"),
        require("./images/140643257/3.jpg"),
        require("./images/140643257/4.jpg"),
        require("./images/140643257/5.jpg"),
        require("./images/140643257/6.jpg"),
        require("./images/140643257/8.jpg"),
        require("./images/140643257/9.jpg"),
        require("./images/140643257/10.jpg"),
        require("./images/140643257/11.jpg"),
        require("./images/140643257/12.jpg"),
        require("./images/140643257/13.jpg"),
    ]
  },
  {
    id: '137875093',
    images: [
      require("./images/137875093/0.jpg"),
      require("./images/137875093/2.jpg"),
      require("./images/137875093/3.jpg"),
      require("./images/137875093/4.jpg"),
      require("./images/137875093/5.jpg"),
      require("./images/137875093/6.jpg"),
      require("./images/137875093/7.jpg"),
      require("./images/137875093/8.jpg"),
      require("./images/137875093/9.jpg"),
      require("./images/137875093/10.jpg"),
      require("./images/137875093/11.jpg"),
      require("./images/137875093/12.jpg"),
      require("./images/137875093/13.jpg"),
      require("./images/137875093/14.jpg"),
      require("./images/137875093/15.jpg"),
      require("./images/137875093/16.jpg"),
      require("./images/137875093/17.jpg"),
    ]
  },
  {
    id: '144000357',
    images: [
      require("./images/144000357/0.jpg"),
      require("./images/144000357/1.jpg"),
      require("./images/144000357/2.jpg"),
      require("./images/144000357/3.jpg"),
      require("./images/144000357/4.jpg"),
      require("./images/144000357/5.jpg"),
      require("./images/144000357/6.jpg"),
      require("./images/144000357/7.jpg"),
      require("./images/144000357/8.jpg"),
      require("./images/144000357/9.jpg"),
      require("./images/144000357/10.jpg"),
      require("./images/144000357/11.jpg"),
      require("./images/144000357/12.jpg"),
    ]
  },
  {
    id: '141068917',
    images: [
      require("./images/141068917/0.jpg"),
      require("./images/141068917/1.jpg"),
      require("./images/141068917/2.jpg"),
      require("./images/141068917/3.jpg"),
      require("./images/141068917/4.jpg"),
      require("./images/141068917/5.jpg"),
      require("./images/141068917/6.jpg"),
      require("./images/141068917/7.jpg"),
      require("./images/141068917/8.jpg"),
      require("./images/141068917/9.jpg"),
      require("./images/141068917/10.jpg"),
    ]
  },
  {
    id: '138369065',
    images: [
      require("./images/138369065/0.jpg"),
      require("./images/138369065/1.jpg"),
      require("./images/138369065/2.jpg"),
      require("./images/138369065/3.jpg"),
      require("./images/138369065/4.jpg"),
      require("./images/138369065/5.gif"),
      require("./images/138369065/6.jpg"),
      require("./images/138369065/7.jpg"),
      require("./images/138369065/8.jpg"),
      require("./images/138369065/9.jpg"),
      require("./images/138369065/10.jpg"),
      require("./images/138369065/11.gif"),
      require("./images/138369065/12.jpg"),
      require("./images/138369065/13.jpg"),
      require("./images/138369065/14.jpg"),
      require("./images/138369065/15.jpg"),
      require("./images/138369065/16.jpg"),
      require("./images/138369065/17.jpg"),
      require("./images/138369065/18.jpg"),
      require("./images/138369065/19.jpg"),
      require("./images/138369065/20.jpg"),
      require("./images/138369065/21.jpg"),
      require("./images/138369065/22.jpg"),
      require("./images/138369065/23.jpg"),
    ]
  }
];

export default config;
