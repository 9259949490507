import { Link, NavLink } from "react-router-dom";
import { styled } from "styled-components";
import mainLogo from "../images/main_logo.svg";
import mainLogoWhite from "../images/main_logo_white.svg";
import connectBE from "../images/connect_be.svg";
import { MenuOutlined } from "@ant-design/icons";
import { useState } from "react";

const Container = styled.div`
  width: 100%;
  height: 65px;
  @media screen and (max-width: 1024px) {
    height: 120px;
  }
`;

const FixContainer = styled.div`
  width: 100%;
  height: 65px;
  background: white;
  display: flex;
  align-items: flex-start;
  perspective: 1200px;
  @media screen and (max-width: 1024px) {
    background: black;
    position: fixed;
    height: 120px;
    z-index: 1000;
  }
`;

const Logo = styled.img`
  width: 70px;
  margin: 17px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const WhiteLogo = styled.img`
  width: 160px;
  margin: 17px;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const ConnectBEContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
const ConnectBE = styled.img`
  margin: 22px 22px 0 0;
`;

const NavLinks = styled.div`
  display: flex;
  @media screen and (max-width: 1024px) {
    margin-left: 20px;
  }
`;

const TheNavLink = styled(NavLink)`
  padding: 17px 0 0 0;
  margin: 0 13px;
  text-decoration: none;
  display: block;
  text-wrap: nowrap;
  @media screen and (max-width: 1024px) {
    display: none;
    p:nth-of-type(2) {
      opacity: 1;
      color: white;
    }
    &.active {
      display: block;
      font-size: 36px;
    }
  }
  &.active p:nth-of-type(1) {
    background: #e83e11;
  }
  &:hover p:nth-of-type(1) {
    background: #e83e11;
  }
  &:hover p:nth-of-type(2) {
    opacity: 1;
  }
`;

const ShowNavLinksMobile = styled.div`
  font-size: 36px;
  color: white;
  border: 1px solid white;
  border-radius: 50%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 24px;
  top: 24px;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const NavLinksMobileContainer = styled.div`
  width: 100%;
  background: black;
  position: fixed;
  z-index: 1000;
  top: 120px;
  left: 0;
  padding: 0 0 80px 0;
  animation: fadeIn 0.5s;
  @keyframes fadeIn {
    from {
      opacity: 0.4;
      transform: scale(1.1) rotateX(-20deg);
    }
    to {
      opacity: 1;
      transform: scale(1) rotateX(0);
    }
  }
`;

const NavLinksMobileList = styled.div`
  width: 1000px;
  margin: 0 auto 100px;
`;

const NavLinksMobileListLink = styled(TheNavLink)`
  color: white;
  font-size: 62px;
  text-decoration: none;
  margin: 40px 0;
  display: flex;
  font-weight: 100;
`;

const NavLinksMobileListLinkText = styled.div`
  width: 50%;
`;

const NavLinksMobileListLinkTextEN = styled.div``;

const NavLinksMobileListLinkTextCN = styled.div``;

const NavLinksMobileListLinkSubInfo = styled.div`
  font-size: 34px;
  p {
    margin: 4px 0;
  }
`;

const NavLinksMobileBackButton = styled.button`
  width: 1000px;
  height: 300px;
  display: block;
  margin: 50px auto 0;
  border: 1px solid white;
  background: none;
  font-size: 88px;
  color: white;
`;

const NavLinkText = styled.p`
  color: black;
  margin: 0;
`;

const NavLinkTextEN = styled(NavLinkText)``;

const NavLinkTextCN = styled(NavLinkText)`
  opacity: 0;
`;

const NavLinkContact = styled(TheNavLink)`
  &.active p:nth-of-type(1) {
    background: #e828e8;
  }
  &:hover p:nth-of-type(1) {
    background: #e828e8;
  }
`;

const NavLinkContactEN = styled(NavLinkText)``;

const NavBar = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <Container>
      <FixContainer>
        <Logo src={mainLogo} />
        <WhiteLogo src={mainLogoWhite} />
        <NavLinks>
          <TheNavLink to="/">
            <NavLinkTextEN>PROJECTS</NavLinkTextEN>
            <NavLinkTextCN>项目</NavLinkTextCN>
          </TheNavLink>
          <TheNavLink to="/esg">
            <NavLinkTextEN>ESG</NavLinkTextEN>
            <NavLinkTextCN>环境</NavLinkTextCN>
          </TheNavLink>
          <TheNavLink to="/news">
            <NavLinkTextEN>NEWS</NavLinkTextEN>
            <NavLinkTextCN>动态</NavLinkTextCN>
          </TheNavLink>
          <TheNavLink to="/about">
            <NavLinkTextEN>ABOUT</NavLinkTextEN>
            <NavLinkTextCN>介绍</NavLinkTextCN>
          </TheNavLink>
          <NavLinkContact to="/contact">
            <NavLinkContactEN>CONTACT</NavLinkContactEN>
            <NavLinkTextCN>联络我们</NavLinkTextCN>
          </NavLinkContact>
        </NavLinks>
        <ConnectBEContainer>
          <Link to="https://www.behance.net/min_zhou87d7">
            <ConnectBE src={connectBE} />
          </Link>
        </ConnectBEContainer>
        <ShowNavLinksMobile
          onClick={() => {
            setMenuVisible(!menuVisible);
          }}
        >
          <MenuOutlined />
        </ShowNavLinksMobile>
        {menuVisible && (
          <NavLinksMobileContainer>
            <NavLinksMobileList>
              <NavLinksMobileListLink to="/">
                <NavLinksMobileListLinkText>
                  <NavLinksMobileListLinkTextEN>
                    PROJECTS
                  </NavLinksMobileListLinkTextEN>
                  <NavLinksMobileListLinkTextCN>
                    项目
                  </NavLinksMobileListLinkTextCN>
                </NavLinksMobileListLinkText>
                <NavLinksMobileListLinkSubInfo>
                  <p>品牌设计</p>
                  <p>商品包装设计</p>
                  <p>视觉表达</p>
                </NavLinksMobileListLinkSubInfo>
              </NavLinksMobileListLink>
              <NavLinksMobileListLink to="/esg">
                <NavLinksMobileListLinkText>
                  <NavLinksMobileListLinkTextEN>
                    ESG
                  </NavLinksMobileListLinkTextEN>
                  <NavLinksMobileListLinkTextCN>
                    环境
                  </NavLinksMobileListLinkTextCN>
                </NavLinksMobileListLinkText>
                <NavLinksMobileListLinkSubInfo>
                  <p>生产链路减碳</p>
                  <p>材料与结构</p>
                </NavLinksMobileListLinkSubInfo>
              </NavLinksMobileListLink>
              <NavLinksMobileListLink to="/news">
                <NavLinksMobileListLinkText>
                  <NavLinksMobileListLinkTextEN>
                    NEWS
                  </NavLinksMobileListLinkTextEN>
                  <NavLinksMobileListLinkTextCN>
                    动态
                  </NavLinksMobileListLinkTextCN>
                </NavLinksMobileListLinkText>
              </NavLinksMobileListLink>
              <NavLinksMobileListLink to="/contact">
                <NavLinksMobileListLinkText>
                  <NavLinksMobileListLinkTextEN>
                    ABOUT
                  </NavLinksMobileListLinkTextEN>
                  <NavLinksMobileListLinkTextCN>
                    介绍
                  </NavLinksMobileListLinkTextCN>
                </NavLinksMobileListLinkText>
                <NavLinksMobileListLinkSubInfo>
                  <p>工作动态</p>
                  <p>日常</p>
                </NavLinksMobileListLinkSubInfo>
              </NavLinksMobileListLink>
              <NavLinksMobileListLink to="/contact">
                <NavLinksMobileListLinkText>
                  <NavLinksMobileListLinkTextEN>
                    CONTACT
                  </NavLinksMobileListLinkTextEN>
                  <NavLinksMobileListLinkTextCN>
                    联络我们
                  </NavLinksMobileListLinkTextCN>
                </NavLinksMobileListLinkText>
              </NavLinksMobileListLink>
            </NavLinksMobileList>
            <NavLinksMobileBackButton
              onClick={() => {
                setMenuVisible(false);
              }}
            >
              BACK
            </NavLinksMobileBackButton>
          </NavLinksMobileContainer>
        )}
      </FixContainer>
    </Container>
  );
};

export default NavBar;
