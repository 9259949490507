import axios from "axios";

const apiRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

apiRequest.interceptors.request.use(
  (config) => {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default apiRequest;
